import React from "react";
import Navbar from "./navbar/Navbar";

function IndexDashboard() {
  return (
    <div>
      <h2>This is the home page</h2>
    </div>
  );
}

export default IndexDashboard;
