import React from "react";
import { deletePayment } from "../../actions";

class PaymentDelete extends React.Component {
  render() {
    return <div>Payment Delete</div>;
  }
}

export default PaymentDelete;
