import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import { useParams } from "react-router-dom";
import Select from "@material-ui/core/Select";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Snackbar from "@material-ui/core/Snackbar";
import background from "./../../assets/images/home/path.jpg";
import UpperFooter from "../ui/UpperFooter";

import { baseURL } from "./../../apis/util";

import theme from "./../ui/Theme";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "100%",
    //height: "100%",
    //height: 350,
    width: "100%",

    marginLeft: "0em",
    //borderRadius: 30,
    marginTop: "17em",
    marginBottom: "1em",
    padding: 0,
  },
  rootMobile: {
    maxWidth: "100%",
    //height: 440,
    //height: "100%",
    width: "100%",

    marginLeft: "0px",
    //borderRadius: 30,
    marginTop: "17em",
    marginBottom: "3em",
    padding: 0,
    backgroundColor: "#FFFFFF",

    "&:hover": {
      //border: "solid",
      //borderColor: theme.palette.common.grey,
    },
  },
  mediaMobile: {
    height: 150,
    width: 150,
    marginLeft: "80px",
  },
  media: {
    height: "100%",
    width: "100%",
    //marginLeft: "80px",
    //marginTop: "80px",
  },
  footer: {
    width: "100%",
    marginTop: "10rem",
  },

  learnButton: {
    ...theme.typography.learnButton,
    fontSize: "0.7rem",
    height: 35,
    padding: 5,
    marginTop: "55px",
    marginLeft: "160px",
    border: `2px solid ${theme.palette.common.blue}`,
    [theme.breakpoints.down("sm")]: {
      marginBottom: "2em",
    },
  },
  dialog: {
    //maxWidth: 325,
    maxWidth: 500,
    //height: 450,
    marginLeft: "10px",
    borderRadius: 30,
    //marginTop: "10em",
    padding: 0,
    marginTop: -20,
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "250px",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  background: {
    backgroundImage: `url(${background})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    //backgroundAttachment: "fixed",
    backgroundRepeat: "no-repeat",
    height: "60em",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      // backgroundImage: `url(${mobileBackground})`,
      backgroundAttachment: "inherit",
    },
  },
}));

export default function ThankYou() {
  const classes = useStyles();
  const params = useParams();
  const [open, setOpen] = useState(false);
  const [openLoginForm, setOpenLoginForm] = useState(false);
  const [openSignUpForm, setOpenSignUpForm] = useState(false);
  const [openForgotPasswordForm, setOpenForgotPasswordForm] = useState(false);

  const [currencyName, setCurrencyName] = useState("naira");
  const [countryName, setCountryName] = useState();
  const [stateName, setStateName] = useState();
  const [product, setProduct] = useState({});

  const [learningPath, setLearningPath] = useState("crash-courses");

  const [expanded, setExpanded] = useState(false);
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    backgroundColor: "",
  });
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
  const matchesMDUp = useMediaQuery(theme.breakpoints.up("md"));

  const action = params.action;
  const placementNumber = params.placementNumber;

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  let imageUrl = "";
  if (product) {
    imageUrl = `${baseURL}/images/courses/${product.imageCover}`;
  }

  return (
    <>
      {matchesMDUp ? (
        <Card className={classes.root}>
          {/* <CardActionArea disableRipple disableTouchRipple> */}
          <Grid
            container
            direction="row"
            justifyContent="center"
            //style={{ backgroundColor: "white" }}
          >
            <Grid
              item
              style={{
                width: "100%",
                //border: "1px dotted white",
                // backgroundColor: "white",
                marginLeft: 250,
              }}
            >
              {action === "quotes" && (
                <CardContent>
                  <Typography
                    variant="h5"
                    color="textSecondary"
                    component="p"
                    style={{ marginLeft: "5rem", marginBottom: 15 }}
                  >
                    Your Request for a Quote is Received. We will get back to
                    you as soon as possible. Thank You
                  </Typography>
                  <span style={{ marginLeft: 280, fontSize: 14 }}>
                    Your Quote Number is {placementNumber}
                  </span>
                </CardContent>
              )}
              {action === "deals" && (
                <CardContent>
                  <Typography
                    variant="h4"
                    color="textSecondary"
                    component="p"
                    style={{ marginLeft: "20rem", marginBottom: 15 }}
                  >
                    Your Deal Proposal is Received. We will get back to you as
                    soon as possible.
                  </Typography>
                  <span style={{ marginLeft: 280, fontSize: 14 }}>
                    Your Proposed Deal Number is {placementNumber}
                  </span>
                </CardContent>
              )}
              {(action !== "deals" || action !== "quotes") && (
                <CardContent>
                  <Typography
                    variant="h4"
                    color="textSecondary"
                    component="p"
                    style={{ marginLeft: "20rem", marginBottom: 15 }}
                  >
                    Thank You
                  </Typography>
                  {/* <span style={{ marginLeft: 280, fontSize: 14 }}>
                    Your Proposed Deal Number is {placementNumber}
                  </span> */}
                </CardContent>
              )}
            </Grid>
            <Grid item className={classes.footer}>
              <UpperFooter />
            </Grid>
          </Grid>
          {/* </CardActionArea> */}
        </Card>
      ) : (
        // <Card className={classes.rootMobile} disableRipple>
        <Grid
          container
          direction="column"
          justifyContent="center"
          className={classes.rootMobile}
        >
          <Grid
            item
            style={{
              width: "100%",
              //border: "1px dotted white",
              // backgroundColor: "white",
              marginLeft: 10,
            }}
          >
            {action === "quotes" && (
              <CardContent>
                <Typography
                  variant="h4"
                  color="textSecondary"
                  component="p"
                  style={{ marginLeft: "7rem", marginBottom: 15 }}
                >
                  Your Request for a Quote is Received. We will get back to you
                  as soon as possible. Thank You
                </Typography>
                <span style={{ marginLeft: "8rem", fontSize: 14 }}>
                  Your Quote Number is {placementNumber}
                </span>
              </CardContent>
            )}
            {action !== "quotes" && (
              <CardContent>
                <Typography
                  variant="h4"
                  color="textSecondary"
                  component="p"
                  style={{ marginLeft: "7rem", marginBottom: 15 }}
                >
                  Thank You
                </Typography>
              </CardContent>
            )}
          </Grid>
          <Grid item className={classes.footer}>
            <UpperFooter />
          </Grid>
        </Grid>
        //</Card>
      )}
      <Dialog
        //style={{ zIndex: 1302 }}
        fullScreen={matchesXS}
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          style: {
            paddingTop: matchesXS ? "1em" : "3em",
            marginTop: 110,
            height: 540,
            paddingBottom: "3em",
            paddingLeft: matchesXS
              ? 0
              : matchesSM
              ? "3em"
              : matchesMD
              ? "10em"
              : "2em",
            paddingRight: matchesXS
              ? 0
              : matchesSM
              ? "5em"
              : matchesMD
              ? "10em"
              : "2em",
          },
        }}
      ></Dialog>

      <Snackbar
        open={alert.open}
        message={alert.message}
        ContentProps={{
          style: { backgroundColor: alert.backgroundColor },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setAlert({ ...alert, open: false })}
        autoHideDuration={4000}
      />
    </>
  );
}
